import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  callbackBody: {
    backgroundColor: theme.palette.primary.main,
    height: "100px",
    width: "100%",
    marginTop: "5rem",
    position: "relative",
  },
  btnSecondary: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: " 50%",
    transform: "translate(-50%, -50%)",
    borderWidth: "2px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    // [theme.breakpoints.up("tablet")]: {
    //   margin: "1rem 3rem 0 3rem",
    // },
  },
}));
