import * as React from "react";

import { Link } from "gatsby";
import { Button } from "@material-ui/core";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";

import { useStyles } from "./CallbackBanner.styling";

const CallbackBanner = () => {
  const classes = useStyles();

  return (
    <section className={classes.callbackBody}>
      <Button
        classes={{ root: classes.btnSecondary }}
        variant="outlined"
        endIcon={<PhoneCallbackIcon />}
      >
        <Link style={{color:'inherit', textDecoration:'none'}} to="/callback">Request Callback</Link>
      </Button>
    </section>
  );
};

export default CallbackBanner;
